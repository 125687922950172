const FirebaseConfig_dev = {
  apiKey: "AIzaSyD0BVrxKrZPNsrZx4UhjsnjLhFAkTvU63A",
  authDomain: "test-tunimeet.firebaseapp.com",
  projectId: "test-tunimeet",
  storageBucket: "test-tunimeet.appspot.com",
  messagingSenderId: "460421594965",
  appId: "1:460421594965:web:6d474e092be6d3f3ebb9dc",
  measurementId: "G-BKG3DENR56"
};

const FirebaseConfig_prod = {
  apiKey: "AIzaSyCtj35-UXqiQFC8dXNJuDTbD_Rp7VCNqzg",
  authDomain: "tunimeet-c2692.firebaseapp.com",
  projectId: "tunimeet-c2692",
  storageBucket: "tunimeet-c2692.appspot.com",
  messagingSenderId: "404529772378",
  appId: "1:404529772378:web:7e226e158864381961a563",
  measurementId: "G-TLM3FZHYY3"
};

const FirebaseConfig_test = {
  apiKey: "AIzaSyD0BVrxKrZPNsrZx4UhjsnjLhFAkTvU63A",
  authDomain: "test-tunimeet.firebaseapp.com",
  projectId: "test-tunimeet",
  storageBucket: "test-tunimeet.appspot.com",
  messagingSenderId: "460421594965",
  appId: "1:460421594965:web:6d474e092be6d3f3ebb9dc",
  measurementId: "G-BKG3DENR56"
};

const getFierBaseConfig = () => {
	switch (process.env.NODE_ENV) {
		case "development":
			return FirebaseConfig_dev;
		case "production":
			return FirebaseConfig_prod;
		case "test":
			return FirebaseConfig_test;
		default:
			break;
	}
};

export default getFierBaseConfig()

