import { useSelector } from "react-redux";

const useUserType = (requiredTypes) => {
    const userType = useSelector((state) => Object.entries(state.org.access));

    var hasRequiredType = false;

    if (Array.isArray(requiredTypes)) {
        userType.forEach((typeEntry) => {
            const [typeKey, typeValue] = typeEntry;
            if (requiredTypes[0].includes(typeKey) && typeValue) {
                hasRequiredType = true;
            }
        });
    } else {
        hasRequiredType = userType.some(([key, value]) => key === requiredTypes && value);
    }

    return {
        userType: userType,
        hasRequiredType: hasRequiredType,
        exact: userType.some(([key]) => key === requiredTypes),
    };
};

export default useUserType;