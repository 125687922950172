import fetch from "auth/FetchInterceptor";

const ConferenceServices = {};

ConferenceServices.getConferences = function (id) {
    return fetch({
        url: `/conferences/${id}`,
        method: "GET",
    });
}

ConferenceServices.getConference = function (id) {
    return fetch({
        url: `/conference/${id}`,
        method: "GET",
    });
}

ConferenceServices.createConference = function (id,data) {
    return fetch({
        url: `/conference/${id}`,
        method: "POST",
        data,
    });
}

ConferenceServices.updateConference = function (id, data) {
    return fetch({
        url: `/conference/${id}`,
        method: "PUT",
        data,
    });
}

ConferenceServices.deleteConference = function (id) {
    return  fetch({
        url: `/conference/${id}`,
        method: "DELETE",
    });
}

ConferenceServices.joinConference = function (id) {
    return fetch({
        url:  `/conference/join/${id}`,
        method: "POST",
    });
}

ConferenceServices.activeConference = function (id) {
    return fetch({
        url: `/conference/active/${id}`,
        method: "PUT",
    });
}

ConferenceServices.changeCover = function (data, id) {
    return fetch({
        url: `/conference/cover/${id}`,
        method: "POST",
        data,
    });
}

export default ConferenceServices;
