import fetch from "auth/FetchInterceptor";

const NoteServices = {};

NoteServices.getNotes = function () {
    return fetch({
        url: "/notes",
        method: "GET",
    });
}

NoteServices.add = function (data) {
    return fetch({
        url: "/note",
        method: "POST",
        data: data,
    });
}

NoteServices.update = function (id, data) {
    return fetch({
        url: `/note/${id}`,
        method: "PUT",
        data: data,
    });
}

NoteServices.delete = function (id) {
    return fetch({
        url: `/note/${id}`,
        method: "Delete",
    });
}

NoteServices.getMeetNote = function (id) {
    return fetch({
        url: `/note/meet/${id}`,
        method: "GET",
    });
}

export default NoteServices;
