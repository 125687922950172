import fetch from "auth/FetchInterceptor";


const JwtAuthService = {};

function refreshToken() {
  return fetch({
    url: "/token/refresh",
    method: "GET",
  });
}

JwtAuthService.refreshToken = function () {
  refreshToken().then((resp) => {
  }).catch(error => {
    console.error("Error refreshing token:", error);
  });
  return true;
};

JwtAuthService.login = function (data) {
  return fetch({
    url: "/login_check",
    method: "POST",
    data: data,
  });
};

JwtAuthService.signOut = function () {
  return fetch({
    url: "/user/logout",
    method: "GET",
  });
};

JwtAuthService.signUp = function (data) {
  return fetch({
    url: "/user/signup",
    method: "POST",
    data: data,
  });
};

export default JwtAuthService;