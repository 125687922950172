import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
	SIGNIN,
	SIGNOUT,
	SIGNUP,
	SIGNIN_WITH_GOOGLE,
	SIGNIN_WITH_FACEBOOK,
	ROLE_ADMIN,
} from "../constants/Auth";
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signUpSuccess,
	signInSuccess,
	setUser,
	signInSuccessAdmin,
} from "../actions/Auth";

import JwtAuthService from "services/JwtAuthService";
import FirebaseService from "services/FirebaseService";

import { ChangeOrganisation } from "../actions/Org";


export function* signInwithJWT() {
	yield takeEvery(SIGNIN, function* ({ payload }) {
		const { username, password } = payload;
		try {
			const user = yield call(JwtAuthService.login, {
				username: username,
				password: password,
			});
			if (user?.status === 1) {
				yield put(
					setUser({
						username: user.user,
						role: user.role,
						code: user.code,
						credit: user.credit,
						image: user.image,
						displayName: user.displayName,
						phone: user.phone,
						hasAi: user.hasAi
					})
				);
				yield put(authenticated(true));
				if (user.role === ROLE_ADMIN) {
					yield put(signInSuccessAdmin());
				} else {
					yield put(signInSuccess());
				}
			} else {
				yield put(showAuthMessage("Invalid credentials."));
			}

		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signOut() {
	yield takeEvery(SIGNOUT, function* () {
		try {
			yield put(signOutSuccess());
			yield put(ChangeOrganisation({
				id: "",
				name: "",
				isOwner: false,
				userType: "",
				code: "",
				icon: "",
				phone: "",
				hasAi: false,
				hasConference: false,
				access: {
					startMeet: false,
					addMember: false,
					updateMember: false,
					removeMember: false,
					addTeam: false,
					removeTeam: false,
					updateTeam: false,
					updateConference: false,
					startConference: false,
					isOrg: false
				}
			}));
			yield call(FirebaseService.signOutRequest);
			yield call(JwtAuthService.signOut);
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signUpWithFBEmail() {
	yield takeEvery(SIGNUP, function* ({ payload }) {
		const { email, password } = payload;
		try {
			const user = yield call(
				FirebaseService.signUpEmailRequest,
				email,
				password
			);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				//localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signUpSuccess(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* signInWithFBGoogle() {
	yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
		try {
			const user = yield call(FirebaseService.signInGoogleRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				var JwtUser = null;
				if (user.additionalUserInfo.isNewUser) {
					JwtUser = yield call(JwtAuthService.signUp, {
						username: user.user.email,
						password: user.user.uid,
						displayName: user.user.displayName,
						signWithGoogle: true,
					});
					JwtUser = yield call(JwtAuthService.login, {
						username: user.user.email,
						password: user.user.uid,
					});

				} else {
					JwtUser = yield call(JwtAuthService.login, {
						username: user.user.email,
						password: user.user.uid,
					});
				}
				yield put(
					setUser({
						username: JwtUser.user,
						role: JwtUser.role,
						code: JwtUser.code,
						displayName: JwtUser.displayName,
						image: JwtUser.image,
						phone: JwtUser.phone,
					})
				);
				yield put(authenticated(true));
				yield put(signInSuccess());
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}


export function* signInWithFacebook() {
	yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
		try {
			const user = yield call(FirebaseService.signInFacebookRequest);

			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				var JwtUser = null;
				if (user.additionalUserInfo.isNewUser) {
					JwtUser = yield call(JwtAuthService.signUp, {
						username: user.user.email,
						password: user.user.uid,
						displayName: user.user.displayName,
						signWithGoogle: true,
					});
					JwtUser = yield call(JwtAuthService.login, {
						username: user.user.email,
						password: user.user.uid,
					});
				} else {
					JwtUser = yield call(JwtAuthService.login, {
						username: user.user.email,
						password: user.user.uid,
					});
				}
				yield put(
					setUser({
						username: JwtUser.user,
						role: JwtUser.role,
						code: JwtUser.code,
						displayName: JwtUser.displayName,
						image: JwtUser.image,
						phone: JwtUser.phone,
					})
				);
				yield put(authenticated(true));
				yield put(signInSuccess());
			}
		} catch (error) {
			yield put(showAuthMessage(error.message));
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(signInwithJWT),
		fork(signOut),
		fork(signUpWithFBEmail),
		fork(signInWithFBGoogle),
		fork(signInWithFacebook),

	]);
}
