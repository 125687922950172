import React, { useEffect, useRef, useState } from 'react';
import { Button, Input } from 'antd';
import MeetServices from 'services/MeetServices';
import { env } from 'configs/EnvironmentConfig';
const speechsdk = require('microsoft-cognitiveservices-speech-sdk')

const { TextArea } = Input;
const AiModel = ({ updateDiscussionRef, discussion, meetId, isMuted }) => {
    const [displayText, setDisplayText] = useState(discussion ? discussion : '');
    const [savedDiscussion, setSavedDiscussion] = useState('');
    const savedDiscussionRef = useRef(savedDiscussion);
    const recognizerRef = useRef(null);

    useEffect(() => {
        savedDiscussionRef.current = savedDiscussion;
    }, [savedDiscussion]);

    useEffect(() => {
        const speechConfig = speechsdk.SpeechConfig.fromSubscription(
            env.SUBSCRIPTIONKEY,
            "francecentral"
        );
        speechConfig.speechRecognitionLanguage = 'ar-TN';

        const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);
        recognizerRef.current = recognizer;

        recognizer.startContinuousRecognitionAsync(
            () => {
                console.log("Continuous recognition started");
            },
            (err) => {
                console.error("Error starting continuous recognition: ", err);
                setDisplayText('ERROR: Failed to start continuous recognition.');
            }
        );

        recognizer.recognized = (s, e) => {
            if (e.result.reason === speechsdk.ResultReason.RecognizedSpeech) {
                const now = new Date();
                const formattedDate = new Intl.DateTimeFormat('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                }).format(now).replace(',', ':');
                setDisplayText((prevDisplayText) => `${prevDisplayText}\n${e.result.text}`);
                setSavedDiscussion(`${e.result.text} ${formattedDate}`);
            }
        };

        return () => {
            recognizer.stopContinuousRecognitionAsync(
                () => {
                    MeetServices.saveDiscussion({ discussion: savedDiscussionRef.current }, meetId).then((res) => {
                        console.log('Discussion saved');
                    }).catch((err) => {
                        console.error(err);
                    });
                },
                (err) => {
                    console.error("Error stopping continuous recognition: ", err);
                }
            );
        };
    }, [meetId]);

    useEffect(() => {
        if (isMuted) {
            recognizerRef.current.stopContinuousRecognitionAsync(
                () => {
                    MeetServices.saveDiscussion({ discussion: savedDiscussionRef.current }, meetId).then((res) => {
                        console.log('Discussion saved');
                        setSavedDiscussion('');
                    }).catch((err) => {
                        console.error(err);
                    });
                    console.log("Recognition stopped due to mute");
                },
                (err) => {
                    console.error("Error stopping recognition: ", err);
                }
            );
        } else {
            recognizerRef.current.startContinuousRecognitionAsync(
                () => {
                    console.log("Recognition started due to unmute");
                },
                (err) => {
                    console.error("Error starting recognition: ", err);
                }
            );
        }
    }, [isMuted]);
    useEffect(() => {
        updateDiscussionRef(savedDiscussion);
    }, [savedDiscussion, updateDiscussionRef]);

    return (
        <>
            <div>
                <TextArea
                    value={savedDiscussion}
                    className='bg-white position-absolute'
                    style={{ width: '20%', height: '85%', top: '5rem' }}
                    readOnly
                />
                <Button ghost className='position-absolute text-white' style={{ right: '65rem', top: '41rem' }}>
                    Ai : {!isMuted ? "on" : "off"}
                </Button>
            </div>
        </>
    );
};

export default AiModel;