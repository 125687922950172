import React, { useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';
// DOM model
import 'tinymce/models/dom/model'
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';

// importing plugin resources
import 'tinymce/plugins/emoticons/js/emojis';

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */

function BundledEditor(props) {
    const { init, ...rest } = props;
    // note that skin and content_css is disabled to avoid the normal
    // loading process and is instead loaded as a string via content_style
    return (
        <Editor
            init={{
                ...init,
                skin: false,
                content_css: false,
                content_style: [init.content_style || ''].join('\n'),
            }}
            {...rest}
        />
    );
}

// export default function RichTextEditor({ name, initialValue, form }) {

//     return (

//         <BundledEditor
//             apiKey={
//                 "fiowmbbgkxejlamhmc1fzzdxvfof2qlpvd83ltjsc514qrva"
//             }
//             init={{
//                 height: 300,
//                 menubar: false,
//                 plugins: 'textcolor colorpicker tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss',
//                 toolbar: 'undo redo | blocks fontfamily fontsize | forecolor backcolor | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
//                 content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
//             }}
//             value={form.getFieldValue('description') ? form.getFieldValue('description') : form.getFieldValue('content')}
//             onEditorChange={(newValue, editor) => {
//                 form.setFieldsValue({ [name]: newValue })
//             }}

//         />
//     );
// }
export default function RichTextEditor({ name, initialValue, form, onEditorChange }) {
    useEffect(() => {
        form.setFieldsValue({ [name]: initialValue });
    }, [initialValue, form, name]);

    return (
        <BundledEditor
            apiKey="fiowmbbgkxejlamhmc1fzzdxvfof2qlpvd83ltjsc514qrva"
            init={{
                height: 300,
                menubar: false,
                plugins: 'textcolor colorpicker tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss',
                toolbar: 'undo redo | blocks fontfamily fontsize | forecolor backcolor | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
            value={form.getFieldValue(name)}
            onEditorChange={(newValue, editor) => {
                form.setFieldsValue({ [name]: newValue });
                onEditorChange?.(newValue)
            }}
        />
    );
}
