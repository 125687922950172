import {
  DashboardOutlined,
  UserOutlined,
  TeamOutlined,
  VideoCameraOutlined,
  CalendarOutlined,
  SettingOutlined,
  DollarOutlined,
  FileTextOutlined,
  PlusOutlined,
  BankOutlined,
  SendOutlined,
  BookOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'


const NewSideBar = [
  {
    key: 'dashboard',
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: 'sidenav.dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'dashboard',
        path: `${APP_PREFIX_PATH}/dashboard`,
        title: 'sidenav.dashboard',
        icon: DashboardOutlined,
        premission: ['ROLE_CLIENT'],
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'dashboard',
        path: `${APP_PREFIX_PATH}/admin/home`,
        title: 'sidenav.dashboard',
        icon: DashboardOutlined,
        breadcrumb: false,
        premission: ['ROLE_ADMIN'],
        submenu: []
      },
    ]
  },
  {
    key: 'meetings',
    path: `${APP_PREFIX_PATH}/meetings`,
    title: 'sidenav.meetings',
    icon: DashboardOutlined,
    breadcrumb: false,
    premission: ['ROLE_CLIENT'],
    submenu: [
      {
        key: 'meetings',
        path: `${APP_PREFIX_PATH}/meetings/all`,
        title: 'sidenav.meetings',
        icon: VideoCameraOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'sidenav.meetings.calendar',
        path: `${APP_PREFIX_PATH}/meetings/calendar`,
        title: 'sidenav.meetings.calendar',
        icon: CalendarOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'Note',
        path: `${APP_PREFIX_PATH}/meetings/notes`,
        title: 'Notes',
        icon: FileTextOutlined ,
        breadcrumb: false,
        submenu: []
      }
    ]
  },

  {
    key: 'conference',
    path: `${APP_PREFIX_PATH}/conferences`,
    title: 'Conferences',
    icon: DashboardOutlined,
    breadcrumb: false,
    premission: ['ROLE_CLIENT'],
    userType: ['isOrg'],
    submenu: [
      {
        key: 'conferences',
        path: `${APP_PREFIX_PATH}/conferences`,
        title: 'Conferences',
        icon: VideoCameraOutlined,
        breadcrumb: false,
        submenu: []
      },
    ]
  },

  {
    key: 'members',
    path: `${APP_PREFIX_PATH}/users`,
    title: 'sidenav.members',
    icon: TeamOutlined,
    breadcrumb: false,
    premission: ['ROLE_CLIENT'],
    submenu: [
      {
        key: 'users',
        path: `${APP_PREFIX_PATH}/users/member/list`,
        title: 'sidenav.members',
        icon: UserOutlined,
        submenu: [],
        breadcrumb: false,
      },
      {
        key: 'join_rrquest',
        path: `${APP_PREFIX_PATH}/users/request`,
        title: 'sidenav.members.join.request',
        icon: SendOutlined,
        breadcrumb: false,
        userType: ["addMember"],
        submenu: []
      },
    ]
  },
  {
    key: 'teams',
    path: `${APP_PREFIX_PATH}/team`,
    title: 'sidenav.teams',
    icon: TeamOutlined,
    breadcrumb: false,
    userType: ['isOrg'],
    premission: ['ROLE_CLIENT'],
    submenu: [
      {
        key: 'add_team',
        path: `${APP_PREFIX_PATH}/team/add`,
        title: 'sidenav.teams.add',
        icon: PlusOutlined,
        breadcrumb: false,
        userType: ['addTeam'],
        submenu: [],
      },
      {
        key: 'team_list',
        path: `${APP_PREFIX_PATH}/team/list`,
        title: 'sidenav.teams.list',
        icon: TeamOutlined,
        userType: ['isOrg'],
        breadcrumb: false,
        submenu: []
      },
    ]
  },
  {
    key: 'users',
    path: `${APP_PREFIX_PATH}/users`,
    title: 'users',
    breadcrumb: false,
    premission: ['ROLE_ADMIN'],
    submenu: [
      {
        key: 'add_user',
        path: `${APP_PREFIX_PATH}/admin/users/add`,
        title: 'sidenav.users.add',
        icon: PlusOutlined,
        submenu: [],
        breadcrumb: false,
      },
      {
        key: 'users',
        path: `${APP_PREFIX_PATH}/admin/users/list`,
        title: 'sidenav.users.list',
        icon: TeamOutlined,
        submenu: [],
        breadcrumb: false,
      },
    ]
  },
  {
    key: 'organisation',
    path: `${APP_PREFIX_PATH}/organisations`,
    title: 'profile.organisation',
    breadcrumb: false,
    premission: ['ROLE_ADMIN'],
    submenu: [
      {
        key: 'add_organisation',
        path: `${APP_PREFIX_PATH}/admin/organisations/add`,
        title: 'sidenav.organisation.add',
        icon: PlusOutlined,
        submenu: [],
        breadcrumb: false,
      },
      {
        key: 'organisations',
        path: `${APP_PREFIX_PATH}/admin/organisations/list`,
        title: 'sidenav.organisation.list',
        icon: BankOutlined,
        submenu: [],
        breadcrumb: false,
      },
    ]
  },
  {
    key: 'settings',
    path: `${APP_PREFIX_PATH}`,
    title: 'sidenav.settings',
    icon: TeamOutlined,
    breadcrumb: false,
    premission: ['ROLE_CLIENT'],
    submenu: [
      {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/setting`,
        title: 'sidenav.settings',
        icon: SettingOutlined,
        breadcrumb: false,
        submenu: []
      }
    ]
  },
  {
    key: 'pricing',
    path: `${APP_PREFIX_PATH}/plan`,
    title: 'sidenav.pricing',
    icon: DashboardOutlined,
    breadcrumb: false,
    premission: ['ROLE_CLIENT'],
    submenu: [
      {
        key: 'pricing',
        path: `${APP_PREFIX_PATH}/plan/list`,
        title: 'sidenav.pricing',
        icon: DollarOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'current',
        path: `${APP_PREFIX_PATH}/plan/current`,
        title: 'sidenav.pricing.current',
        icon: ClockCircleOutlined,
        breadcrumb: false,
        submenu: []
      },

    ]
  },
  {
    key: 'plan',
    path: `${APP_PREFIX_PATH}/admin/plan`,
    title: 'sidenav.plan',
    icon: DollarOutlined,
    breadcrumb: false,
    premission: ['ROLE_ADMIN'],
    submenu: [
      {
        key: 'add-plan',
        path: `${APP_PREFIX_PATH}/admin/plan/add`,
        title: 'sidenav.plan.add',
        icon: PlusOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'plans',
        path: `${APP_PREFIX_PATH}/admin/plan/list`,
        title: 'sidenav.plan.list',
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: []
      },
    ]
  },
  {
    key: 'feature',
    path: `${APP_PREFIX_PATH}/admin/feature`,
    title: 'sidenav.feature',
    icon: DollarOutlined,
    breadcrumb: false,
    premission: ['ROLE_ADMIN'],
    submenu: [
      {
        key: 'add-feature',
        path: `${APP_PREFIX_PATH}/admin/feature/add`,
        title: 'sidenav.feature.add',
        icon: PlusOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'features',
        path: `${APP_PREFIX_PATH}/admin/feature/list`,
        title: 'sidenav.feature.list',
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: []
      },
    ]
  },
  {
    key: 'tutorial',
    path: `${APP_PREFIX_PATH}`,
    title: 'sidenav.tutorial',
    icon: DollarOutlined,
    breadcrumb: false,
    premission: ['ROLE_CLIENT'],
    submenu: [
      {
        key: 'tutorial',
        path: `${APP_PREFIX_PATH}/tutorial`,
        title: 'sidenav.tutorial',
        icon: BookOutlined,
        breadcrumb: false,
        premission: ['ROLE_CLIENT'],
        submenu: []
      },
    ]
  },
  {
    key: 'setUp',
    path: `${APP_PREFIX_PATH}/setup`,
    title: 'setUp',
    breadcrumb: false,
    premission: ['ROLE_ADMIN'],
    submenu: [
      {
        key: 'setUp',
        path: `${APP_PREFIX_PATH}/admin/setup`,
        title: 'SetUp',
        icon: SettingOutlined,
        submenu: [],
        breadcrumb: false,
      },
    ]
  },

]

const navigationConfig = [
  ...NewSideBar
]



export default navigationConfig;
