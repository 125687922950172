import { CHANGE_ORAGNISATION } from "redux/constants/Org";

const initState = {
	id: "",
	name: "",
	isOwner: false,
	userType: "",
	code: "",
	icon: "",
	phone: "",
	hasAi: false,
	hasConference:false,
	access: {
		startMeet: false,
		addMember: false,
		updateMember: false,
		removeMember: false,
		addTeam: false,
		removeTeam: false,
		updateTeam: false,
		updateConference:false,
		startConference: false,
		isOrg: false
	},
};

const org = (state = initState, action) => {
	switch (action.type) {
		case CHANGE_ORAGNISATION: {
			return {
				...state,
				...action.org,
			}
		}
		default:
			return state;
	}
}

export default org