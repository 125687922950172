import React from 'react';
import { connect } from 'react-redux';
import Loading from 'components/shared-components/Loading';
import MobileNav from 'components/layout-components/MobileNav'
import PageHeader from 'components/layout-components/PageHeader';
import {
    Layout,
    Grid,
} from "antd";

import utils from 'utils';
import { useThemeSwitcher } from "react-css-theme-switcher";
import Room from 'views/room-views';

const { useBreakpoint } = Grid;

export const RoomLayout = ({ navCollapsed, navType, location, direction }) => {
    const screens = utils.getBreakPoint(useBreakpoint());
    const isMobile = !screens.includes('lg')

    const { status } = useThemeSwitcher();

    if (status === 'loading') {
        return <Loading cover="page" />;
    }


    return (
        <Layout>
            <div style={{
                minHeight: '100vh',
                position: 'relative',
            }}>
                <PageHeader />
                <Room location={location} />
            </div>
            {/*<Footer />*/}
            {isMobile && <MobileNav />}
        </Layout>
    )
}

const mapStateToProps = ({ theme }) => {
    const { navCollapsed, navType, locale } = theme;
    return { navCollapsed, navType, locale }
};

export default connect(mapStateToProps)(React.memo(RoomLayout));
