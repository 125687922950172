import React, { useState } from "react";
import { Menu, Dropdown, Avatar, Tooltip, Modal, Input, Button, Form } from "antd";
import { connect, useDispatch } from "react-redux";
import {
    SettingOutlined,
    LogoutOutlined,
    EditOutlined,
    UserSwitchOutlined,
    HomeOutlined,
    BankOutlined,
    DashboardOutlined
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut,signIn } from "redux/actions/Auth";
import { useHistory } from "react-router-dom";
import { setLocale } from "utils";
import { env } from "configs/EnvironmentConfig";


export const NavProfile = (props) => {
    const {
        signOut,
        displayName,
        code,
        image,
        isOwner,
        role,
        signIn,
    } = props;
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm()

    let history = useHistory();
    let profileImg = "/img/avatars/thumb-1.png";
    let menuItem;

    if (role !== "ROLE_ADMIN") {
        menuItem = [
            {
                title:setLocale(true, "sidenav.dashboard"),
                icon: HomeOutlined,
                path: "/app/home"
            },
            {
                title: setLocale(true, "profile.edit"),
                icon: EditOutlined,
                path: "/app/setting/edit-profile"
            },
            {
                title: setLocale(true, "profile.password.change"),
                icon: SettingOutlined,
                path: "/app/setting/change-password"
            },
        ];
    } else {
        menuItem = [
            {
                title: setLocale(true, "sidenav.dashboard"),
                icon: DashboardOutlined,
                path: "/app/admin/home"
            },
            {
                title: setLocale(true, "profile.edit"),
                icon: EditOutlined,
                path: "/app/setting/edit-profile"
            },
        ];
    }

    if (isOwner) {
        menuItem.unshift({
            title: setLocale(true, "organisation.settings"),
            icon: BankOutlined,
            path: "/app/setting/organisation"
        });
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = async (values) => {
        await dispatch(signOut());
        signIn(values);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const profileMenu = (
        <>
            <Modal title="Switch Account" visible={isModalVisible} onOk={() => form.submit()} onCancel={handleCancel} footer={[
                <Button key="start" type="primary" onClick={() => form.submit()}>
                    Switch
                </Button>,
            ]}>
                <Form form={form} onFinish={handleOk}>
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input placeholder="Username" className="mb-2" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input placeholder="Password" type="password" />
                    </Form.Item>
                </Form>
            </Modal>
            <div className="nav-profile nav-dropdown">
                <div className="nav-profile-header">
                    <div className="d-flex position-relative">
                        {image ?
                            <Avatar size={50} src={env.IMG_SRC + image}></Avatar>
                            :
                            <Avatar size={50} src={profileImg} />
                        }
                        <div className="pl-3">
                            <h4 className="mb-0">{displayName}</h4>
                            <p className="mb-0">{code}</p>
                        </div>
                    </div>
                </div>
                <div className="nav-profile-body">
                    <Menu>
                        {menuItem.map((el, i) => {
                            return (
                                <Menu.Item key={i}>
                                    <a href={el.path}>
                                        <Icon className="mr-3" type={el.icon} />
                                        <span className="font-weight-normal">{el.title}</span>
                                    </a>
                                </Menu.Item>
                            );
                        })}
                        <Menu.Divider />
                        <Menu.Item
                            key={7}
                            onClick={showModal}
                        >
                            <span>
                                <UserSwitchOutlined className="mr-3" />
                                <span className="font-weight-normal">{setLocale(true, "profile.switch")}</span>
                            </span>
                        </Menu.Item>
                        <Menu.Item
                            key={6}
                            onClick={(e) => {
                                dispatch(signOut());
                                history.push("/auth/login");
                            }}
                        >
                            <span>
                                <LogoutOutlined className="mr-3" />
                                <span className="font-weight-normal">{setLocale(true, "profile.signout")}</span>
                            </span>
                        </Menu.Item>
                    </Menu>
                </div>
            </div>
        </>
    );

    return (
        <>
            <Dropdown
                placement="bottomRight"
                overlay={profileMenu}
                trigger={["click"]}
                visible={dropdownVisible}
                onVisibleChange={setDropdownVisible}
            >
                <Menu className="d-flex align-item-center" mode="horizontal">
                    <Menu.Item key="profile">
                        <Tooltip title={setLocale(true, "profile")}>
                            {image ?
                                <Avatar src={env.IMG_SRC + image}></Avatar>
                                :
                                <Avatar src={profileImg} />
                            }
                        </Tooltip>
                    </Menu.Item>
                </Menu>
            </Dropdown>
        </>
    );
};

const mapStateToProps = ({ auth, org }) => {
    const { displayName, role, code, image } = auth;
    const isOwner = org.isOwner;
    return { displayName, role, code, image, isOwner };
};

export default connect(mapStateToProps, { signOut,signIn })(NavProfile);
