import React, { useEffect, useRef, useState } from 'react';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form} from "antd";
import MeetServices from "../../services/MeetServices";
import Loading from "../../components/shared-components/Loading";
import ConferenceServices from 'services/ConferenceServices';
import AiModel from './aiModel';

const ConferenceRoom = (props) => {
    const { location } = props;
    const [form] = Form.useForm();
    const [hasAi, setHasAi] = useState(false);
    const { username } = useSelector(state => ({
        username: state.auth.username,
    }));
    const [discussion, setDiscussion] = useState('');
    const AiModal = location.state?.hasAi?.hasAi || false;
    const [displayName, setDisplayName] = useState(username);
    const history = useHistory();
    const YOUR_DOMAIN = 'tunimeet.francecentral.cloudapp.azure.com';

    const queryParams = new URLSearchParams(location.search);
    const ref = queryParams.get('id');


    const discussionRef = useRef(discussion); // Create a ref

    useEffect(() => {
        ConferenceServices.getConference(ref).then((res) => {
            if (res.code === 200) {
                ConferenceServices.joinConference(ref)
                    .then(resp => {
                        console.log('log');
                    })
                    .catch(error => {
                        console.error('Error joining conference:', error);
                    });
            }
        });
    }, [ref]);

    useEffect(() => {
        discussionRef.current = discussion; // Update the ref whenever discussion changes
    }, [discussion]);

    const updateDiscussionRef = (transcript) => {
        discussionRef.current = transcript;
    };
    
    return (
        <>
            {(AiModal || hasAi) && (
                <AiModel
                    onSaveDiscussion={setDiscussion}
                    updateDiscussionRef={updateDiscussionRef}
                    meetingCreator={username} // Assuming the current user is the meeting creator
                    username={username}
                />
            )}
            <JitsiMeeting
                domain={YOUR_DOMAIN}
                roomName={ref}
                spinner={() => <Loading cover="page" />}
                configOverwrite={{
                    startWithAudioMuted: true,
                    disableModeratorIndicator: true,
                    startScreenSharing: true,
                    enableEmailInStats: false
                }}
                interfaceConfigOverwrite={{
                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true
                }}
                userInfo={{
                    displayName: displayName
                }}
                onApiReady={(externalApi) => {
                    // here you can attach custom event listeners to the Jitsi Meet External API
                    // you can also store it locally to execute commands
                    // listen for an event
                    externalApi.addEventListener('readyToClose', () => {
                        const email = username !== "" ? username : form.getFieldValue('email');
                        const meetingInfo = {
                            meetRef: ref,
                            username: email,
                            discussion: discussionRef.current
                        };
                        MeetServices.endMeeting(meetingInfo).then(() => {
                            history.push('/app/home');
                        });
                    });

                    externalApi.addEventListener('videoConferenceLeft', (obj) => {
                        const email = username !== "" ? username : form.getFieldValue('email');
                        const meetingInfo = {
                            meetRef: obj.roomName,
                            username: email,
                            discussion: discussionRef.current
                        };
                        MeetServices.endMeeting(meetingInfo).then(() => {
                            history.push('/app/home');
                        });
                    });
                }}
                getIFrameRef={(iframeRef) => {
                    iframeRef.style.height = '100vh';
                }}
            />
        </>
    );
};

export default ConferenceRoom;