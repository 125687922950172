import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import useAuth from "hooks/useAuth";
import useUserType from "hooks/useType";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;



const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const useConstructor = (callBack = () => { }) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  const data = callBack();
  if (hasBeenCalled) return data;
  setHasBeenCalled(true);
  return data;
};

const MenuContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
  } = props;
  const data = useConstructor(() => {

    return navigationConfig;
  });

  const IsAuth = (premission) => {
    const { isAuth: isAuthenticated } = useAuth([premission]);
    return isAuthenticated;
  };

  const ExactAuth = (premission) => {
    const { exact } = useAuth([premission]);
    return exact;
  };

  const IsType = (requiredType) => {
    const { hasRequiredType } = useUserType([requiredType]);
    return hasRequiredType;
  };

  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {data.map((menu) =>
        menu.submenu.length > 0 ? (
          menu.exactAuth !== true ? (
            IsAuth(menu.premission) ? (
              (menu.userType ? IsType(menu.userType) : true) ? (
                <Menu.ItemGroup
                  key={menu.key}
                  title={setLocale(localization, menu.title)}
                >
                  {menu.submenu.map((subMenuFirst) =>
                    subMenuFirst.submenu.length > 0 ? (
                      IsAuth(subMenuFirst.premission) ? (
                        (subMenuFirst.userType ? IsType(subMenuFirst.userType) : true) ? (
                          <SubMenu
                            icon={
                              subMenuFirst.icon ? (
                                <Icon type={subMenuFirst?.icon} />
                              ) : null
                            }
                            key={subMenuFirst.key}
                            title={setLocale(localization, subMenuFirst.title)}
                          >
                            {subMenuFirst.submenu.map((subMenuSecond) =>
                              IsAuth(subMenuSecond.premission) ? (
                                (subMenuSecond.userType ? IsType(subMenuSecond.userType) : true) ? (
                                  <Menu.Item key={subMenuSecond.key}>
                                    {subMenuSecond.icon ? (
                                      <Icon type={subMenuSecond?.icon} />
                                    ) : null}
                                    <span>
                                      {setLocale(localization, subMenuSecond.title)}
                                    </span>
                                    <Link
                                      onClick={() => closeMobileNav()}
                                      to={subMenuSecond.path}
                                    />
                                  </Menu.Item>
                                ) : null
                              ) : null
                            )}
                          </SubMenu>
                        ) : null
                      ) : null
                    ) : IsAuth(subMenuFirst.premission) ? (
                      (subMenuFirst.userType ? IsType(subMenuFirst.userType) : true) ? (

                        <Menu.Item key={subMenuFirst.key}>
                          {subMenuFirst.icon ? (
                            <Icon type={subMenuFirst.icon} />
                          ) : null}
                          <span>{setLocale(localization, subMenuFirst.title)}</span>
                          <Link
                            onClick={() => closeMobileNav()}
                            to={subMenuFirst.path}
                          />
                        </Menu.Item>
                      ) : null
                    ) : null
                  )}
                </Menu.ItemGroup>
              ) : null
            ) : null
          ) : ExactAuth(menu.premission) ? (
            <Menu.ItemGroup
              key={menu.key}
              title={setLocale(localization, menu.title)}
            >
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  IsAuth(subMenuFirst.premission) ? (
                    <SubMenu
                      icon={
                        subMenuFirst.icon ? (
                          <Icon type={subMenuFirst?.icon} />
                        ) : null
                      }
                      key={subMenuFirst.key}
                      title={setLocale(localization, subMenuFirst.title)}
                    >
                      {subMenuFirst.submenu.map((subMenuSecond) =>
                        IsAuth(subMenuSecond.premission) ? (
                          <Menu.Item key={subMenuSecond.key}>
                            {subMenuSecond.icon ? (
                              <Icon type={subMenuSecond?.icon} />
                            ) : null}
                            <span>
                              {setLocale(localization, subMenuSecond.title)}
                            </span>
                            <Link
                              onClick={() => closeMobileNav()}
                              to={subMenuSecond.path}
                            />
                          </Menu.Item>
                        ) : null
                      )}
                    </SubMenu>
                  ) : null
                ) : IsAuth(subMenuFirst.premission) ? (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? (
                      <Icon type={subMenuFirst.icon} />
                    ) : null}
                    <span>{setLocale(localization, subMenuFirst.title)}</span>
                    <Link
                      onClick={() => closeMobileNav()}
                      to={subMenuFirst.path}
                    />
                  </Menu.Item>
                ) : null
              )}
            </Menu.ItemGroup>
          ) : null
        ) : IsAuth(menu.premission) ? (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? (
              <Link onClick={() => closeMobileNav()} to={menu.path} />
            ) : null}
          </Menu.Item>
        ) : null
      )}
    </Menu>
  );

};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
