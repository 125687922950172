import antdJaJP from 'antd/es/locale/ar_EG';
import arMsg from "../locales/ar_EG.json";

const ArLang = {
  antd: antdJaJP,
  locale: 'ar',
  messages: {
    ...arMsg
  },
};
export default ArLang;