import React, { useEffect, useRef, useState } from 'react';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card, Form, Input, message, Modal } from "antd";
import { VideoCameraOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import MeetServices from "../../services/MeetServices";
import Loading from "../../components/shared-components/Loading";
import UserService from 'services/UserService';
import IntlMessage from 'components/util-components/IntlMessage';
import AiModel from './aiModel';
import Note from './Note';

const RoomMeet = (props) => {
    const { location, Data } = props;
    const [form] = Form.useForm();
    const { state } = location;
    const [meetData, setMeetData] = useState(Data ? Data : state);
    const [hasAi, setHasAi] = useState(Data?.hasAi ? Data?.hasAi : false);
    const { username, name } = useSelector(state => ({
        username: state.auth.username,
        name: state.auth.displayName
    }));
    const [discussion, setDiscussion] = useState('');
    const AiModal = location.state?.hasAi || false;
    const [displayName, setDisplayName] = useState(username);
    const queryParams = new URLSearchParams(location.search);
    const querryRef = queryParams.get('id');
    const [ref, setRef] = useState(querryRef);
    const [showNote, setShowNote] = useState(false);
    const [noteText, setNoteText] = useState('');
    const [isEnd, setIsEnd] = useState(false);
    const [isMuted, setIsMuted] = useState(false);

    const Ai_IMG_Base64 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACBUlEQVR4nO2Wv08VQRDHpyERRP4CKaBRLNUCaAH/LIjW4I9EobcghBA6QHoIvVqphRZgNLFQGtGXfMzAXHLgvXvv9uYOVveTbO5l7s3O97u7szmRRCIugFFgA/hhYxO4JRGa+MbfaOymxAJnO6FsqXAb2xZbl1jg7CiRX33bJeW7xALwtcTIF4kFYM1Eb5sBHTsWW5VYAMaBzwXNrrFxiQlgDHiZM6G/xyRWMCR2SEYaBLgDPAbeAMf4c2xzLwETTRgYAJ4BHdqjAzzV2p4mdm3yE+AFMAUMuxQ4X2sYmAaWrZbyysWM7YRyCNx1Udxf3XvAkdV+4tETHVud1kxkAPeBX8Bv4LaEYo2tPJdLAlgxDYt1Jnlrk0y6qqumYdo0vK4zSXbFujd2BQ0jtT//s3uw5P0Q8Aj4YH2kz4fAoHPOKY0YMUEHFLNfJCwkp5cODyO6qspHYAa4DswCnyy+4JHThhE9EsrMhficxd975FQ20mW7y4ycFF0EwA2L//TIqawtwEi2urNdVvedR06wkV6x3Du9abLzPmffSQ9y533eI6eytgAjg3bTFLEHXPPIadxITtiCNqmdf33OdxNUI6dZI21BFW3JSAuQduRf6JEi5JKhhHp/7iMnBPEwUjbBVTEioYQW8IpnJCMZaUeMuvF+3/cktEAy0vSOXBXkvzeSSEgwfwCNiHQakxChNQAAAABJRU5ErkJggg=="
    const NOTE_Base64 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+UlEQVR4nO2ayxGDMAwFfSRNkRJDWgrNAE1sxoxPTBg+km0FtAU8eWVbB0wIjuOIAR7AGxgpzwh0QKMhEiVq02mITCmsFYcdr92m2qNG2IzKymrWx0V0wHfkakcL6H+MwX6roJRQSOTzdyJHkRYkt8jeIBdJ4DuSaWpt5Ra/I5ycWoJ6M9q5agsxI8LOoMuJSAkuojS1csHKTmWbWmZFrICWyN4g8S1PuMgWa50qBdL6t51aKBG0RY5iXoTSHVngImc7YvZocdfvWrlAKmIFtERqdwQXWeA7YvBoTRUfQ5+aj6Hxnbs2Lw2RJgYBQwWBIdWW/zDgODfmC4gtQGVZjwoXAAAAAElFTkSuQmCC"

    const backgroundStyle = {
        backgroundImage: 'url(/img/others/img-17.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    };
    const history = useHistory();
    const YOUR_DOMAIN = 'tunimeet.francecentral.cloudapp.azure.com';

    const discussionRef = useRef(discussion); // Create a ref
    const setLocale = (isLocaleOn, localeKey) =>
        isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

    let urlParams = new URLSearchParams(location.search);
    let idValue = urlParams?.get('id');
    let passwordValue = urlParams.get('password');
    let emailValue = urlParams.get('email');

    useEffect(() => {
        if (username === "" && emailValue) {
            UserService.userExiste({ username: emailValue }).then(resp => {
                if (!resp.data.existe) {
                    const { confirm } = Modal;
                    confirm({
                        title: "You don't have an account on this website. Do you want to create one?",
                        icon: <ExclamationCircleOutlined />,
                        onOk() {
                            UserService.createGuest({ username: emailValue }).then(() => {
                                message.success("Account created successfully");
                            });
                        },
                        onCancel() {
                            console.log('Cancel');
                        },
                    });
                }
            });
        }
    }, [username, emailValue]);

    useEffect(() => {
        discussionRef.current = discussion; // Update the ref whenever discussion changes
    }, [discussion]);

    const updateDiscussionRef = (transcript) => {
        discussionRef.current = transcript;
    };

    const handleStartMeeting = () => {
        form.validateFields()
            .then(values => {
                MeetServices.joinMeeting(values)
                    .then(resp => {
                        setMeetData(resp.data.meet);
                        if (!ref) {
                            setRef(resp?.data?.meet?.ref);
                        }
                        setDisplayName(values.displayName);
                        setHasAi(resp.data.hasAi);
                        history.push({ pathname: '/room', search: '?id=' + resp?.data?.meet?.ref });
                        // window.open('/room?id=' + resp?.data?.meet?.ref, '_blank');
                    })
                    .catch(errorInfo => {
                        console.log(errorInfo);
                    });
            })
            .catch(errorInfo => {
                console.log(errorInfo);
            });
    };

    const handleNoteClick = () => {
        setShowNote(prevShowNote => !prevShowNote);
    };
    const handleSaveNote = (text) => {
        setNoteText(text);
    };


    if (meetData === undefined) {
        return (
            <div style={backgroundStyle}>
                <div className={'container d-flex flex-column justify-content-center'} style={{ height: '100vh', width: 500 }}>
                    <Card centered>
                        <div className="text-center">
                            <img className="img-fluid mb-3" src={`/img/logo.png`} alt="" />
                            {!username &&
                                <p>{setLocale(true, "login.dontHaveAccount")} <a href={'/auth/register-1'}>{setLocale(true, "profile.signup")}</a></p>
                            }
                        </div>
                        <Form form={form} layout="vertical" requiredMark={false}>
                            <Form.Item
                                name="displayName"
                                label={setLocale(true, "profile.displayName")}
                                rules={[{ required: true, message: 'Please enter display name' }]}
                                initialValue={emailValue ? '' : name}
                            >
                                <Input placeholder="Please enter display name" />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                label={setLocale(true, "profile.email")}
                                rules={[{ required: true, message: 'Please enter email name' }]}
                                initialValue={emailValue ? emailValue : username}
                            >
                                <Input placeholder="Please enter email name" />
                            </Form.Item>
                            <Form.Item
                                name="roomRef"
                                label={setLocale(true, "ref")}
                                rules={[{ required: true, message: 'Please enter room reference' }]}
                                initialValue={idValue}
                            >
                                <Input placeholder="Please enter room reference" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label={setLocale(true, "login.password")}
                                initialValue={passwordValue}
                            >
                                <Input.Password placeholder="Please enter password" />
                            </Form.Item>
                            <div>
                                <Button type={'primary'} onClick={handleStartMeeting} icon={<VideoCameraOutlined />}>
                                    Start Meeting
                                </Button>
                            </div>
                        </Form>
                    </Card>
                </div>
            </div>
        );
    }

    return (
        <>
            {(AiModal || hasAi) && (
                <AiModel
                    onSaveDiscussion={setDiscussion}
                    discussion={discussion}
                    updateDiscussionRef={updateDiscussionRef}
                    meetingCreator={meetData?.createdBy}
                    username={username}
                    meetId={meetData.id}
                    isMuted={isMuted}
                />
            )}

            {(showNote || isEnd) && (
                <div className='position-fixed' style={{ width: '70vh', top: '45vh', height: '40vh', backgroundColor: 'white' }}>
                    <Note onSaveNote={handleSaveNote} noteText={noteText} isEnd={isEnd} meetId={meetData.id} meetName={meetData.name} />
                </div>
            )}

            <JitsiMeeting
                domain={YOUR_DOMAIN}
                roomName={meetData?.ref}
                spinner={() => <Loading cover="page" />}
                configOverwrite={{
                    startWithAudioMuted: false,
                    disableModeratorIndicator: true,
                    startScreenSharing: true,
                    enableEmailInStats: false,
                    callDisplayName: meetData?.name,
                    prejoinPageEnabled: false,
                    customToolbarButtons: [
                        (AiModal || hasAi) && {
                            icon: Ai_IMG_Base64,
                            id: 'Speech-recognize-ai',
                            text: 'Speech Recognize AI',
                        },
                        {
                            icon: NOTE_Base64,
                            id: 'note',
                            text: 'Note',
                        }
                    ],
                    // toolbarButtons: [
                    //     'camera',
                    //     'chat',
                    //     'closedcaptions',
                    //     // 'desktop',
                    //     // 'download',
                    //     // 'embedmeeting',
                    //     // 'etherpad',
                    //     // 'feedback',
                    //     // 'filmstrip',
                    //     'fullscreen',
                    //     'hangup',
                    //     //'help',
                    //     //'invite',
                    //     //'livestreaming',
                    //     'microphone',
                    //     'mute-everyone',
                    //     'mute-video-everyone',
                    //     'participants-pane',
                    //     // 'profile',
                    //     // 'raisehand',
                    //     // 'recording',
                    //     // 'security',
                    //     //'select-background',
                    //     //'settings',
                    //     //'shareaudio',
                    //     //'sharedvideo',
                    //     //'shortcuts',
                    //     //'stats',
                    //     //'tileview',
                    //     // 'toggle-camera',
                    //     //'videoquality',
                    //     //'__end'
                    // ],
                }}
                interfaceConfigOverwrite={{
                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                }}
                userInfo={{
                    displayName: displayName
                }}

                onApiReady={(externalApi) => {
                    // here you can attach custom event listeners to the Jitsi Meet External API
                    // you can also store it locally to execute commands
                    // listen for an event
                    externalApi.addEventListener('readyToClose', () => {
                        const email = username !== "" ? username : form.getFieldValue('email');
                        const meetingInfo = {
                            meetRef: ref,
                            username: email,
                            discussion: discussionRef.current
                        };
                        setIsEnd(true)
                        MeetServices.endMeeting(meetingInfo).then(() => {
                            setTimeout(() => {
                                window.close();
                            }, 2000);
                        });
                    });

                    externalApi.addEventListener('videoConferenceLeft', (obj) => {
                        const email = username !== "" ? username : form.getFieldValue('email');
                        const meetingInfo = {
                            meetRef: obj.roomName,
                            username: email,
                            discussion: discussionRef.current
                        };
                        setIsEnd(true)
                        MeetServices.endMeeting(meetingInfo).then(() => {
                            setTimeout(() => {
                                window.close();
                            }, 2000);
                        });
                    });

                    externalApi.addEventListener('audioMuteStatusChanged', (event) => {
                        setIsMuted(event.muted);
                    });

                    externalApi.addListener('toolbarButtonClicked', (event) => {
                        // if (event.key === 'Speech-recognize-ai') {
                        //     handleAiModelClick();
                        // }
                        if (event.key === 'note') {
                            handleNoteClick();
                        }
                    });

                }}
                getIFrameRef={(iframeRef) => {
                    iframeRef.style.height = '100vh';
                }}
            />
        </>
    );
};

export default RoomMeet;