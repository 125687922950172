import React from 'react'
import {Card,Result} from "antd";
const Meesage = (props => {
    const { message } = props
    const backgroundStyle = {
        backgroundImage: 'url(/img/others/img-17.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }
    return (
        <div style={backgroundStyle}>
        <div className={'container d-flex flex-column justify-content-center'} style={{ height: '100vh', width: 500 }}>
            <Card
                centered
            >
                <Result
                    status="info"
                    title={message}
                />
            </Card>
        </div>
    </div>
    )
})
export default Meesage
