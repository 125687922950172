import { Form } from 'antd';
import RichTextEditor from 'components/shared-components/RichTextEditor';
import React, { useEffect } from 'react';
import NoteServices from 'services/NoteServices';

const Note = ({ onSaveNote, noteText, isEnd, meetId, meetName }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({ content: noteText });
    }, [noteText, form]);

    const handleFormChange = (changedValues) => {
        if (changedValues.content !== undefined) {
            onSaveNote(changedValues.content);
        }
    };

    useEffect(() => {
        if (isEnd && noteText!=="") {
            const values = {
                content: noteText,
                meetId: meetId,
                meetName: meetName,
                type: "private"
            };

            NoteServices.add(values).then((res) => {
                console.log(values);
                console.log('Note created');
            }).catch((err) => {
                console.error(err);
            });
        }
    }, [isEnd, noteText, meetId, meetName]);

    return (
        isEnd ? null : (
                <Form form={form} layout="vertical" onValuesChange={handleFormChange}>
                    <Form.Item name="content">
                        <RichTextEditor name="content" form={form} onEditorChange={onSaveNote} />
                    </Form.Item>
                </Form>
        )
    );
};

export default Note;